






















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import Common from '../../common/Common'
import PolicyComponent from '@/components/PolicyComponent.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import VueUploadComponent from 'vue-upload-component'
import markdownIt from "markdown-it"
import Cropper from 'cropperjs'

@Component({
  components: {
    ClipLoader,
    VueUploadComponent,
    PolicyComponent
  },
})
export default class SettingUser extends Vue {
  isUploading = true
  isUpdate = false
  md = new markdownIt({html: false, breaks: true})
  userInfo: any = {name: '', sex: '', selfIntro: '', fileExist: false, detail: '', date: '', web: '', role: 'patient', hasMainRegistered: false}
  registredEmail = ''
  skills: any = {} // safariで動かないためskillsだけ外だし
  categories: any[] = []
  errText = ''
  selfIntroSelectionStart = 0
  cropper: any = ''
  imageEdit = false
  files: any[] = []
  fileErrorMessage = ''
  autoCompletes: {text: string}[] = []

  mounted() {
    let promises = []
    promises.push(new Promise((resolve) => {
      firebase.firestore().collection('tags').get().then((data) => {
        data.docs.forEach((value, index) => {
          let tag = value.data().tag
          this.autoCompletes.push(tag)
        })
        resolve()
      })
    }))
    

    promises.push(new Promise((resolve) => {
      let categoryPromises = []
      categoryPromises.push(new Promise((categoryResolve) => {
        Common.getCategory().then((categories: any) => {
          let copyCategories = Array.from(categories)
          copyCategories.forEach((value: any) => {
            value.skills = []
          })
          this.categories = copyCategories
          categoryResolve()
        })
      }))

      let skills: any[] = []
      categoryPromises.push(new Promise((categoryResolve) => {
        firebase.firestore().collectionGroup('skills').orderBy('name').get().then((data) => {
          data.docs.forEach((value) => {
            let obj = value.data()!
            obj.categoryId = value.ref.parent.parent!.id
            skills.push(obj)
          })
          categoryResolve()
        })
      }))

      Promise.all(categoryPromises).finally(() => {
        skills.forEach((skill) => {
          this.categories.find((category) => {
            return category.id == skill.categoryId
          }).skills.push(skill)
        })
        resolve()
      })
    }))

    promises.push(new Promise((resolve) => {
      sessionStorage.removeItem('user')
      Common.getUserInfo().then((userInfo) => {
        let userPromises = []
        if (userInfo) {
          this.isUpdate = true
          this.userInfo = userInfo
          this.skills = this.userInfo.skills ? this.userInfo.skills : {}
        }
        
        if (this.userInfo.fileExist) {
          userPromises.push(new Promise((userResolve) => {
            Common.getBlob(Common.createUserIconURL(this.userInfo.uid, this.userInfo.fileExist)).then((blob: any) => {
              blob.name = 'image'
              this.files.push(blob)
              this.imageEdit = true
            }).finally(() => {
              userResolve()
            })
          }))
        }

        Promise.all(userPromises).finally(() => {resolve()})
      })
    }))

    Promise.all(promises).finally(() => {
      for(let categoryKey in this.skills) {
        if (Object.keys(this.skills[categoryKey]).length != 0) {
          this.$root.$emit('bv::toggle::collapse', 'collapse-category' + (Number(categoryKey) - 1))
        }
        for(let skill in this.skills[categoryKey]) {
          for (let i=0; i<this.categories.length;i++) {
            if (this.categories[i].value == categoryKey && !this.categories[i].skills.find((s: any) => {return s.name == skill})) {
              this.categories[i].skills.push({name: skill})
              break
            }
          }
        }
      }
      this.isUploading = false
    })
  }

  @Watch('imageEdit')
  imageEditFunc(value: any) {
    if (value) {
      this.$nextTick(function () {
        if (!this.$refs.editImage) {
          return
        }
        let cropper = new Cropper((<any>this.$refs).editImage, {
          aspectRatio: 1 / 1,
          viewMode: 2,
          autoCropArea: 1
        })
        this.cropper = cropper
      })
    } else {
      if (this.cropper) {
        this.cropper.destroy()
        this.cropper = false
      }
    }
  }

  create() {
    this.isUploading = true
    let uid = Common.getUserId()

    this.userInfo.fileExist = this.files.length > 0
    this.userInfo.skills = this.skills
    let skillNames: any[] = []
    let categoryValues: any[] = []
    for(let k in this.skills) {
      categoryValues.push(k)
      if(this.skills.hasOwnProperty(k)) {
        for (let skill in this.skills[k]) {
          skillNames.push({level: this.skills[k][skill], name: skill})
        }
      }
    }
    skillNames.sort((a, b) => {
      return b.level - a.level
    })
    skillNames = skillNames.map((value) => {return value.name})
    this.userInfo.skillArray = skillNames // 検索用にスキル名だけの配列を作成
    this.userInfo.categoryArray = categoryValues // 検索用にカテゴリー値だけの配列を作成
    this.userInfo.createTime = this.userInfo.createTime ? this.userInfo.createTime : firebase.firestore.FieldValue.serverTimestamp()

    let email = this.userInfo.email
    firebase.firestore().collection('users').doc(uid).set(this.userInfo).then(async () => {
      sessionStorage.removeItem('user')
      await this.fileGenerate()
      if (this.isUpdate) {
        location.href = '/profile'
      } else {
        location.href = '/home'
      }
    }).catch((error: any) => {
      console.error(error)
      this.userInfo.email = email
      this.isUploading = false
      this.errText = '登録に失敗しました'
    })
  }

  skillCheck(value: string, name: string, element: any) {
    if (element.target.checked) {
      if (!this.skills[value]) {
        this.$set(this.skills, value, {})
      }
      this.$set(this.skills[value], name, 1)
    } else {
      this.$delete(this.skills[value], name)
    }
  }

  addSkill(category: any, index: number) {
    if (category.otherSkill) {
      // category.skills.push({name: category.otherSkill})
      this.$set(category.skills, category.skills.length, {name: category.otherSkill})
      this.$set(this.skills[category.value], category.otherSkill, '1')
      this.categories[index].otherSkill = ''
    }
  }

  compiledMarkdownText() {
    return this.userInfo.selfIntro ? this.md.render(this.userInfo.selfIntro) : ''
  }

  detailBlur() {
    this.selfIntroSelectionStart = (<any>document.getElementById('selfIntro')!).selectionStart
  }

  inputFile(newFile: any, oldFile:any) {
    if (newFile.size >= 33554432) {
      this.files = []
      this.fileErrorMessage = 'ファイルの容量は32MBまでです'
      return
    }
    this.fileErrorMessage = ''
    this.imageEdit = true
  }

  inputFilter(newFile: any, oldFile:any, prevent:any) {
    newFile.url = ''
    let URL = window.URL || (<any>window).webkitURL
    if (URL && URL.createObjectURL) {
      newFile.url = URL.createObjectURL(newFile.file)
    }
  }

  async fileGenerate() {
    if (this.files.length == 0) {
      return
    }
    Common.UserURL = ''
    let oldFile: any = this.files[0]
    let binStr = atob(this.cropper.getCroppedCanvas({width: 320, height: 320}).toDataURL(oldFile.type).split(',')[1])
    let arr = new Uint8Array(binStr.length)
    for (let i = 0; i < binStr.length; i++) {
      arr[i] = binStr.charCodeAt(i)
    }
    let file = new File([arr], oldFile.name, { type: oldFile.type })
    var storageRef = firebase.storage().ref()
    file = await Common.getCompressImageFileAsync(file)
    await storageRef.child(`users/${Common.getUserId()}/image/`).put(file)
  }

  uploadCancel() {
    this.files.forEach((value, index) => {
      this.files.splice(index, 1)
    })
    this.imageEdit = false
  }

  async updateImageFile(e: any) {
    this.isUploading = true
    let uid = Common.getUserId()
    let file = e.target.files[0]
    var storageRef = firebase.storage().ref()
    let fileName = `${uid}/${Common.createUUID()}${file.name.replace(/.*\./g, '.')}`
    file = await Common.getCompressImageFileAsync(file)
    storageRef.child('users/self/' + fileName).put(file).then(() => {
      let url = Common.createURL(`users/${fileName}`)
      let func = () => {
        this.userInfo.selfIntro = Common.addTextCursor(document.getElementById('selfIntro')!, `![](${url})`)
        this.isUploading = false
      }
      var img = new Image()
      img.src = url
      img.onload = func
      img.onerror = () => {
        setTimeout(() => {
          img.src = url
        }, 500)
      }
    })
  }

  clickImageFileIcon() {
    document.getElementById("imageFile")!.click()
  }
}
